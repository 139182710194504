.flex-ct{
    width:100%;
    display:flex;
    justify-content: space-between;
}
.flex-ev{
    width:100%;
    display:flex;
    justify-content: space-evenly;
}
.list-box{
    width:100%;
    min-height:200px;
    margin:0 15px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
                rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.list-header{
    padding:10px 20px;
    display: flex;
    font-weight: bold;
    border-bottom:1px solid #e8ebec;
    align-items: center;
    font-size: 16px;
}
.list-header img{
    height:40px;
    width:40px;
    margin-right:10px;
}
.list-body{
    font-size: 15px;
    font-weight: 400;
    padding:10px 0;
    padding-bottom: 15px;
    color:#444555;
}

.list-item{
    cursor: pointer;
    padding:10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:1px solid #eaeaea;
}
.list-item button{
    background-color: #3B93F1;
    border:none;
    border-radius: 4px;
    color:#fff;
    padding:5px 8px;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
}
.attempt button{opacity: 0;}
.attempt .list-item:hover > button{
    opacity: 1;
}
.list-body ol li{
    list-style-position: inside;
}
.list-item:hover{
background-color: #f0f0f0;
}
.num{
    padding-right:18px;
}
.sr-90{
    height:35px;
    margin:2.5px 5px;
    width:calc(100% - 10px) !important;
    box-sizing: border-box
  }