.flex-nav{
    display:flex;
    align-items: center;
    color:#666;
    padding:10px;
    border-radius: 6px;
    border-left:5px solid transparent;
    margin-top: 2px;
    font-size: 15px;

}
.flex-nav:hover{
    background-color: rgba(0,0,0,0.05);
}
.nav-icon{
    margin-right: 16px;
}
.pt-25{
    padding-top: 25px;
}
.nav-active .flex-nav{
    background-color: rgba(0,0,0,0.05);
    color:#000;
    border-left:5px solid #5cb85c;
    font-weight: bold;

}
.admin-nav{
    width:300px;
}
.admin-c{
    width:calc(100% - 300px);
    margin-left:300px;
}