.instruction-container{
    background-color: #fff;
    margin:0 15px;
    padding:15px;
    border-radius: 6px;
    display: flex;
    padding-bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, 
                rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.instruction-container > div{
    width:100%;
}
.instruction-container h1{
    font-size: 26px;
    color:#066ead;
    line-height: 20px;
}
footer{
    position: fixed;
    height:50px;
    bottom:0;
    width:100%;
    background-color: #fff;
    border-top: 1px solid #ddd;
}
.footer-in{
    display: flex;
    align-items: center;
    height:100%;
    width:100%;
    padding: 0 25px;
    justify-content: space-between;
}
.footer-in button{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    background-color: #5cb85c;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    border:1px solid rgba(0,0,0,0.1);
    height:35px;
    padding:0 15px;
    font-weight: bold;
    cursor: pointer;
}
.cnt-body{
margin-top: -10px;;
}
.flex-info{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.cnt-body ul{
    font-weight: bold;
    line-height: 25px;
    width: 100%;
    font-size: 15px;
    padding: 0;
    list-style-position: inside;
}
ul.ul-res{list-style-type:none;color:#555;font-weight: normal;}


.ins-b h3{
    color: #066ead;
    font-size: 17px;
}
h1.Big{
    color: #f5832a;
    text-transform: uppercase;
font-size: 26px;
text-align: center;
line-height: 40px;
}
.Big div{
    text-transform: capitalize;
    color:#555;
    font-size: 50px;
}
.fc-b{
    color:#555;
    
}
button.navigate{
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#fff;
    height:34px;
    background-color: #5cb85c;
    padding:0 10px;
    border-radius: 4px;
    border:1px solid rgba(0,0,0,0.1)
}
.flex-info .rew-s {
    margin:20px 0;
}
.f-btn-cnt{
    width:210px;
}
.rightest > div{
height:34px;
margin: 20px 0;
font-size: 14px;
color: #555;
}
.agree{
    display: flex;
    font-size: 14px;
    margin-top: 30px;
    font-weight: 600;
}