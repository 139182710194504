.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
}
.loader{
    height:20px;
    width:20px;
    border-radius: 50%;
    border:2px solid #fff;
    border-top-color:transparent;
    animation:1s linear spin infinite
}
@keyframes spin{
    100%{
        transform:rotate(360deg);
    }
}