@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  flex: 1;
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}
button {
  cursor: pointer;
}
.body-content {
  padding: 20px 40px;
}
.modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal .heading {
  border-bottom: 1px solid #aaa;
  padding-bottom: 15px;
  margin: 0;
}
.table-in {
  margin: 10px;
  max-width: 400px !important;
  width: calc(100% - 20px) !important;
}
.modal-body {
  margin-top: 10px;
  font-size: 18px;
}
.modal-body button {
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 16px;
  background-color: #066ead;
  color: #fff;
  border: none;
  border-radius: 4px;
  height: 36px;
}
.modal-content {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  max-width: 100%;
  margin: 10px;
}
.column-btn {
  display: flex;
  align-items: center;
}
header {
  height: 42px;
  background-color: #066ead;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
}
.table-input {
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  border-color: #aaa;
}

header button {
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0 20px;
  font-weight: bold;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 14px;
  color: #222;
  cursor: pointer;
}
header button:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.content {
  padding: 15px;
}
header .right-menu {
  display: flex;
  color: #fff;
  padding: 0 5px;
  align-items: center;
}
.mb15 {
  margin-bottom: 15px;
}
.wt-bg {
  width: 100%;
  height: calc(100vh - 40px);
  background-color: #fff;
}
.centerSpin {
  display: flex;
  width: 100%;
  height: 20vh;
  justify-content: center;
  align-items: center;
}
.tableLink {
  color: #005285;
}
.error-message {
  padding: 15px 20px;
  border: 1px solid #f6685e;
  font-size: 15px;
  border-radius: 4px;
  margin-top: 10px;
  background-color: rgb(255, 0, 0, 0.1);
  color: #f6685e;
}
.menu-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 12px;
  cursor: pointer;
}
.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.header-link {
  display: flex;
  text-decoration: none;
  color: inherit;
}
td,
th {
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: left;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
th {
  background-color: rgba(1, 28, 53, 0.1);
}
tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.01);
}
button.create:hover {
  background-color: #0f6699;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}
.edit {
  width: 50px;
}
.edit button {
  border: none;
  background-color: rgb(24, 104, 24);
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer !important;
}
.edit-head {
  text-align: center;
}
.delete button {
  background-color: rgb(177, 0, 0);
}
.edit button svg {
  height: 20px;
}
input.input,
.input {
  padding: 10px 12px;
  width: 400px;
  max-width: 100%;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-right: 10px;
  outline: none;
  background: #fff;
  margin-bottom: 10px;
}

button.create {
  padding: 10px 20px;
  border: none;
  color: #fff;
  background-color: #1174ad;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}
#button:disabled {
  opacity: 0.2;
}
.srs .profile-head {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.srs .student-profile {
  border-radius: 2px;
  margin-top: 10px;
}
.srs .profile-body {
  padding: 0;
}
td textarea {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 5px;
  height: 30px;
  box-sizing: border-box;
  width: 200px;
}
table td {
  max-width: 200px !important;
  background-color: #fff;
}

h1.heading {
  font-size: 24px;
  font-weight: normal;
  color: #000;
}
h2.heading {
  font-size: 17px;
  font-weight: normal;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.7);
}
.path-name {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: -15px;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 18px;
}
form {
  padding: 20px;
  background-color: #f1f3f6;
}
select {
  font-size: 16px;
  padding: 10px 12px;
  border: none;
  margin-right: 10px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  margin-right: 10px;
}
option {
  background: #fff;
}
.pdc-15 {
  padding: 15px;
}
.container-x {
  margin-top: 10px;
}
.searchList {
  width: calc(100% - 30px);
  max-width: 500px;
  background-color: #fff;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  position: absolute;
  margin-top: 5px;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 8px 0;
}
button.searchItem {
  padding: 8px 15px;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  width: 100%;
  border: none;
  background-color: #fff;
  text-align: left;
  font-family: inherit;
  text-transform: capitalize;
}
button.searchItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.father {
  font-weight: bold;
  font-size: 14px;
  color: #888;
}
.flex-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555;
}
.Sclass {
  font-size: 15px;
  font-weight: bold;
}
.student-profile {
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}
.profile-head {
  padding: 5px 12px;
  background-color: #7083b4;
  color: #fff;
  text-transform: capitalize;
}
.profile-body {
  padding: 10px 20px;
  padding-bottom: 20px;
}
.flex-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.flex-container .listId {
  width: 200px;
  color: #888;
}
.listInfo {
  text-transform: capitalize;
  color: #003b5f;
}
.table-22 td button {
  background-color: #70afb4;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 2px 10px;
}
.cont-f {
}
.student-profile table {
  border-collapse: collapse;
  margin-top: 0px;
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.student-profile td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 15px;
  background-color: #fff;
  color: #555;
}
.student-profile th {
  background-color: #f5f5f6;
  font-weight: 600;
}
.table-22 {
  margin-top: 20px;
}
.pd-20 {
  padding: 12px;
}

.filter-table {
  margin-bottom: 10px;
}
.filter-table input,
.filter-table button {
  height: 32px;
  border: 1px solid #888;
  border-radius: 2px;
  padding: 0 10px;
  margin-right: 5px;
  color: #888;
  margin-bottom: 10px;
}
.filter-table button {
  background-color: #7083b4;
  color: #fff;
  border: rgba(0, 0, 0, 0.2);
  width: 100px;
}
.overflow-table {
  overflow: auto;
}
.container-x {
  align-items: center;
  width: 100%;
}
.flex-row {
  display: flex;
  align-items: center;
  width: 0px;
  color: #a03333;
}
.container-x input {
  width: 100%;
  max-width: 500px;
  white-space: wrap;
}
.ml-10 {
  margin-left: 10px;
}
.subm {
  font-weight: bold;
}
.right-c form {
  padding: 0;
}

.pdc-10 {
  padding: 15px;
}
.right-menu svg {
  display: none;
}
@media screen and (max-width: 980px) {
  .pd-15 {
    padding: 0 !important;
    margin-top: 10px;
  }
  .pd-15 .student-profile {
    border-radius: 0;
  }
  .left-c {
    z-index: 999;
    position: fixed;
    width: 100% !important;
    left: -100%;
    transition: 0.2s ease;
  }
  .open-c {
    left: 0 !important;
  }
  .right-c {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .right-menu svg {
    display: block !important;
  }
}

@media screen and (max-width: 712px) {
  .form-container {
    max-width: 100%;
    box-shadow: none;
  }
  body {
    background-color: #fff;
  }
  .content {
    padding: 0;
  }
  .form-header {
    text-align: center;
    border: none;
  }
}

.responsive-flex {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  flex-flow: row wrap;
}
.responsive-flex > div {
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  flex-basis: calc(33% - 8px);
}

@media screen and (max-width: 1100px) {
  .responsive-flex > div {
    flex-basis: calc(50% - 10px);
  }
}

@media screen and (max-width: 700px) {
  .responsive-flex > div {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-form input[type="file"] {
  margin-bottom: 10px;
}

.upload-form button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-form button:disabled {
  background-color: #9e9e9e;
}

.upload-form button:hover:not(:disabled) {
  background-color: #45a049;
}

.message {
  margin-top: 10px;
  color: red;
}

.loader-container {
  margin: 20px;
}

.uploaded-file {
  margin-top: 20px;
}

.banners {
  margin-top: 20px;
  width: 100%;
}

.banner-image {
  width: 500px;
  max-width: 100%;
  border-radius: 10px;
  margin: auto;
  display: block;
  border: 1px solid #aaa;
}
.banner-cont {
  max-width: 500px;
  margin: auto;
  width: 100%;
}
.del-banner {
  font-size: 12px;
  color: #fff;
  background-color: rgba(190, 31, 31, 0.5);
  width: max-content;
  padding: 6px 12px;
  border-radius: 100px;
  margin-bottom: -30px;
  margin-left: 5px;
  cursor: pointer;
  position: relative;
}
.del-banner:hover {
  background-color: rgba(190, 31, 31, 1);
}
/* imageUpload.css */
.upload-form input[type="text"],
.upload-form input[type="file"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

.upload-form input[type="file"] {
  padding: 5px;
}

.upload-form input[type="text"]:focus,
.upload-form input[type="file"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.upload-form button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-form button:hover {
  background-color: #0056b3;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.message {
  margin-top: 20px;
  font-size: 18px;
  color: #007bff;
  text-align: center;
}

.banners {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.banner-cont {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.del-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  cursor: pointer;
  border-radius: 3px;
  font-size: 12px;
}

.banner-image {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.sites {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.site {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: calc(50% - 70px);
  transition: transform 0.3s;
}
@media screen and (min-width: 600px) {
  .site {
    width: 200px;
  }
}
.site:hover {
  transform: scale(1.1);
}

.site img {
  width: 100%;
  height: 100px;
  border-radius: 6px;
  object-fit: contain;
}

.site span {
  display: block;
  margin-top: 10px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  padding: 20px !important;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-form input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.upload-form input[type="file"] {
  margin: 10px 0;
}

.upload-form button {
  padding: 10px 20px;
  background-color: #00bfff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-form button:hover {
  background-color: #009acd;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.message {
  text-align: center;
  color: #ff0000;
  font-size: 1.2em;
  margin-top: 10px;
}

.sites {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.site {
  position: relative;
  width: 200px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.site:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

.site img {
  width: 100%;
  min-height: 200px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.site span {
  display: block;
  margin-top: 10px;
  font-size: 1.1em;
  font-weight: bold;
}

.del-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff0000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.del-banner:hover {
  background-color: #cc0000;
}
