.register{
    display: flex;
    justify-content: center;
}
.form-container{
    max-width: 480px;
    width:100%;
    padding:30px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12549019607843137);
}
.input__textBox, .form-container button,.login-button{
    width:100%;
    height:50px;
    border-radius: 3px;
    border:1px solid #d4d3d3;
    padding:0 12px;
    margin-top:3px;
    background-color: #fff;
    font-size: 15px;
}
.main__btn{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.input__textBox:focus{
    outline: 1px solid #066ead;
}
.form-container button{
    background-color: #066ead;
    color:#fff;
    font-size: 15px;
font-weight: bold;
    cursor: pointer;
    border:none
}

.form-header{
    padding-bottom: 12px;
    border-bottom: 1px solid #d4d3d3;
    font-size: 22px;
    color: #222;
}
.form-container .row-input label{
    font-size: 11px;
    color:rgb(71, 71, 71);
    font-weight: 600;

}
.login-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height:40px
}

.login-button a{
    text-decoration: none;
    color:#066ead
    }
.form-container select{
    color:#959699
}
.form-container select option:not(:first-of-type) {
    color: #222;
  }
.form-container .row-input{
    margin:20px 0;
}
.input__textBox::placeholder{
    color:#959699;
}